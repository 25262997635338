import Swiper from 'swiper/dist/js/swiper.js';
import AOS from 'aos';
import Blazy from 'blazy';
import cookieBar from './cookie/jquery.cookiebar.js';
import jqueryui from './jquery-ui/jquery-ui.min.js';
import photobox from './photobox/photobox/jquery.photobox.js';
import datepicker_ita from './lingue_calendario/jquery.ui.datepicker-ita.min.js';
import datepicker_eng from './lingue_calendario/jquery.ui.datepicker-eng.min.js';

jQuery(document).ready(function( $ ) {
  var mySwiperTop = new Swiper ('.gallery_top', {
         direction: 'horizontal',
         effect: 'fade',
         nextButton: '.gallery_top .swiper-button-next',
         prevButton: '.gallery_top .swiper-button-prev',
         autoplay: 2000,
         paginationClickable: true,
         speed: 1000,
         autoplayDisableOnInteraction: true,
         loop: true
      });

 var cont = $('#compare').val();
 for(var i=1; i<= cont; i++){

 new Swiper ('.camere_'+i, {
    direction: 'horizontal',
    nextButton: '.camere_'+i+' .swiper-button-next',
    prevButton: '.camere_'+i+' .swiper-button-prev',
    speed: 1000,
    autoplayDisableOnInteraction: true,
    effect: 'slide',
    loop: true,
 });
 }


var mySwiperOfSlide = new Swiper ('.slider_off_top', {
     direction: 'horizontal',
     pagination: '.swiper-pagination',
     autoplay: 1300,
     speed: 1000,
     spaceBetween: 10,
     paginationClickable: true,
     autoplayDisableOnInteraction: true,
     effect: 'slide',
     nextButton: '.action li .swiper-button-next',
     prevButton: '.action li .swiper-button-prev',
     loop: true,
});

     var bLazy = new Blazy();

     AOS.init({
     disable: 'mobile'
   });


      $(".action > li:first-child" ).click(function(){
           $('.cont_menu').toggleClass("open_menu");
           $('.hamb').toggleClass("hide");
           $('.hambx').toggleClass("hide");
           $('.top').toggleClass("black");

     });

       // $(".mosaico li" ).click(function() {
       // 	var grow =  $(this).hasClass('grow');
       // 		$(".mosaico li" ).removeClass('grow');
       // 		$(".mosaico li.video" ).removeClass('hvideo');
       // 		if($(this).hasClass('more') && !grow){
       // 				 $(this).addClass('grow');
       // 				 $(".mosaico li.video" ).addClass('hvideo');
       // 		}
       // 		 });


            $(".mosaico li .video_mob").each(function(){
               $(this).click(function(){
                 vid = $(this).data( "video" );
                 video(vid);
               });
           });

   var offset = 100,
   offset_opacity = 800,
   scroll_top_duration = 1500,
   $back_to_top = $('.cd-top');

 $(window).scroll(function(){
   if($('#check_zindex').css("z-index")!="767"){
     if( $(this).scrollTop() > offset ){
       $back_to_top.addClass('cd-is-visible');
       $("body").addClass('scroll');
     } else {
       $back_to_top.removeClass('cd-is-visible cd-fade-out');
       $("body").removeClass('scroll');
     }
   }
 });

 $( window ).resize(function() {
   if($('#check_zindex').css("z-index")=="767"){
     $back_to_top.removeClass('cd-is-visible cd-fade-out');
     $("body").removeClass("sticky");
   }


 });



 /*aggiungo classe al li se ha menu secondario*/
 $('.menu_top li .sub-menu').parent('li').addClass('dropdown');

 $( ".menu_top li" ).each(function() {
   if($( this ).hasClass( "dropdown" )){
     $( this ).append('<div class=\"cont-fr\"><i class=\"fa fa-angle-down\" aria-hidden=\"true\"></i></div>');
   }
 });

 /*al click della freccia apro quello cliccato e chiudo gli altri*/
 $( ".cont-fr" ).each(function() {
 $(this).click(function(){
   if($(this).hasClass('rotate')){
     $(this).removeClass('rotate');
   }else{
     $( ".cont-fr" ).removeClass('rotate');
     $(this).addClass('rotate');
   }
  if($(this).parent().find('ul').hasClass("open_sub_menu")){
    $(this).parent().find('ul').removeClass( "open_sub_menu" );
  }else{
    $( ".menu_top ul li ul" ).removeClass( "open_sub_menu" );
      $( ".menu-top-dx ul li ul" ).removeClass( "open_sub_menu" );
      $(this).parent().find('ul').addClass( "open_sub_menu" );

  }
  });
 });



 $(".cont_show").click(function() {
     $('html, body').animate({
       scrollTop: ($("section").offset().top-100)
     }, 1500);
   })

   $(".cont_icon" ).click(function() {
         $(".mosaico_container").toggleClass("open_mos");
         $('html, body').animate({
           scrollTop: ($(".mosaico").offset().top+100)
         }, 1500);
       });


    $('body').photobox('.photobox');

 var min_stay=1;
 if($('#min_stay').val()){
 min_stay = parseInt($('#min_stay').val());
 }
 var min_g_trasacorsi=0;
 if($('#min_g_trascorsi').val()){
 min_g_trasacorsi = parseInt($('#min_g_trascorsi').val());
 }

 var partenza = min_g_trasacorsi + min_stay;


 $("#notti").change(function() {
 $(".notti").html($("#notti option:selected").text());
 });
 $(".notti").html($("#notti option:selected").text());

 $("#adulti").change(function() {
 $(".adulti").html($("#adulti option:selected").text());
 });
 $(".adulti").html($("#adulti option:selected").text());

 cookie();

 var lang =  $('#lang').val();
 $.datepicker.setDefaults($.datepicker.regional[lang]);

 $( "#datepicker" ).datepicker({
   dateFormat:"dd/mm/yy",
   minDate: new Date(),

   onSelect: function(dateText){
     var date = $("#datepicker").datepicker("getDate");

     $(".gg").html($.datepicker.formatDate("dd", date));
     $(".mm").html($.datepicker.formatDate("M", date));
     $(".aa").html($.datepicker.formatDate("y", date));

    prenota_one_calendar();
   }
 }).datepicker( "setDate", "d" );
 var date = $("#datepicker").datepicker("getDate");
 $(".gg").html($.datepicker.formatDate("dd", date));
 $(".mm").html($.datepicker.formatDate("M", date));
 $(".aa").html($.datepicker.formatDate("y", date));
 prenota_one_calendar();



 $( "#tavolo_check_in_date" ).datepicker({
 dateFormat:"dd.mm.yy",
 minDate: new Date(),
 }).datepicker( "setDate", "d" );



 $('.filter').on( "click", function() {
 var filterValue = $(this).attr('data-filter');
 $(".filter").removeClass('active');
 $(this).addClass('active');
 $( ".gallery_isotope li" ).removeClass('hide');

 $( ".gallery_isotope li" ).each(function() {
       var filterClass =  $(this).attr('class');
         if(filterClass != filterValue) $(this).addClass('hide');
         if(filterValue == '*') $( ".gallery_isotope li" ).removeClass('hide');
     });
 });



       $( '.row' ).each(function() {
         $( this ).addClass( "foo" );
       });


       $('#ospiti').on('change', function() {
         $('.row').addClass('hide');
         $('.row[data-ospiti = ' + $('#ospiti').val() + ']').removeClass('hide');
         if($(this).val() == 'all') $('.row').removeClass('hide');
       });

       $('#vista').on('change', function() {
         $('.row').addClass('hide');
         $('.row[data-vista = ' + $('#vista').val() + ']').removeClass('hide');
         if($(this).val() == 'all') $('.row').removeClass('hide');
       });

       $('#balcone').on('change', function() {
         $('.row').addClass('hide');
         $('.row[data-balcone = ' + $('#balcone').val() + ']').removeClass('hide');
         if($(this).val() == 'all') $('.row').removeClass('hide');
       });

       $('#bagni').on('change', function() {
         $('.row').addClass('hide');
         $('.row[data-bagni = ' + $('#bagni').val() + ']').removeClass('hide');
         if($(this).val() == 'all') $('.row').removeClass('hide');
       });

       $('#doccia').on('change', function() {
         $('.row').addClass('hide');
         $('.row[data-doccia = ' + $('#doccia').val() + ']').removeClass('hide');
         if($(this).val() == 'all') $('.row').removeClass('hide');
       });

       $('#soggiorno').on('change', function() {
         $('.row').addClass('hide');
         $('.row[data-soggiorno = ' + $('#soggiorno').val() + ']').removeClass('hide');
         if($(this).val() == 'all') $('.row').removeClass('hide');
       });

       $('#jacuzzi').on('change', function() {
         $('.row').addClass('hide');
         $('.row[data-jacuzzi = ' + $('#jacuzzi').val() + ']').removeClass('hide');
         if($(this).val() == 'all') $('.row').removeClass('hide');
       });


       $('#accappatoio').on('change', function() {
         $('.row').addClass('hide');
         $('.row[data-accappatoio = ' + $('#accappatoio').val() + ']').removeClass('hide');
         if($(this).val() == 'all') $('.row').removeClass('hide');
       });



 });


   function prenota_one_calendar(){
     var split_arrivo = $("#datepicker").val();

      split_arrivo = split_arrivo.split('/');
      var aa_arrivo = split_arrivo[2];
      var mm_arrivo = split_arrivo[1];
      var gg_arrivo = split_arrivo[0];

      $('[name=aa]').val(aa_arrivo);
      $('[name=mm]').val(mm_arrivo);
      $('[name=gg]').val(gg_arrivo);

   }

function getParameter(key) {
   key = key.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
   var regex = new RegExp("[\\?&]" + key + "=([^&#]*)"),
       results = regex.exec(location.search);
   return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
 }

function video(video){
       jQuery( "body" ).append( "<div class=\"video_over\"><i class=\"fa fa-window-close\" aria-hidden=\"true\"></i><video controls=\"controls\" width=\"auto\" height=\"50%\" autoplay><source src=\""+video+"\" type=\"video/mp4\" />Your browser does not support the video tag.</video></div>" );

       jQuery(".fa-window-close").click(function(){
         jQuery(".video_over").remove();
       });
   }

function cookie(){

switch($('#lingua').val()){
  case 'ita':
    var testo = 'Questo sito utilizza cookie tecnici e di profilazione di terze parti. Per ulteriori informazioni o per negare il consenso, leggi la informativa estesa. Proseguendo con la navigazione acconsenti a usare i cookie.';
      var label = 'Accetta e chiudi';
  break;

  default:
    var testo = 'This site uses technical cookies and third party profiling. To learn more or opt out, read the complete cookie policy statement. By continuing to use this site, we assume you are happy with it';

    var label = 'Accept and close';
  break;
}
  $.cookieBar({
    message: testo,
    acceptText: label,
    policyButton: true,
    policyText: 'Privacy Policy',
    policyURL: $('#linkcookie').val(),
    expireDays: 30
  });
}
